import React from 'react';
import { toggleSidebar } from '../features/siteFeatures/siteFeaturesSlice';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  const sidebarOpen = useSelector((state) => state.siteFeatures.sidebarOpen);
  const authUser = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const formattedDate = moment().format('YYYY-MM-DD');
  const sideBarLinkClassNames = "block px-2 py-2 text-gray-200 hover:bg-blue-600";

  return (
    <div className={`fixed inset-0 z-30 transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:relative lg:translate-x-0 xl:mim-w-100 lg:min-w-120 bg-blue-800 text-white flex flex-col`}>
      <nav className="flex-1 space-y-2">
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/dashboard" className={sideBarLinkClassNames}>
          Dashboard
        </NavLink>
        {authUser.user_type.match(/admin|corporate/) && (
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/users" className={sideBarLinkClassNames}>
          Users
        </NavLink>
        )}
        {authUser.user_type.match(/admin/) && (
          <NavLink onClick={() => dispatch(toggleSidebar())} to="/msos" className={sideBarLinkClassNames}>
          MSOs
        </NavLink>
        )}
        {authUser.user_type.match(/admin|corporate/) && (
          <NavLink onClick={() => dispatch(toggleSidebar())} to="/areas" className={sideBarLinkClassNames}>
            Areas
          </NavLink>
        )}
        {authUser.user_type.match(/admin|corporate/) && (
          <NavLink onClick={() => dispatch(toggleSidebar())} to="/companies" className={sideBarLinkClassNames}>
            Companies
          </NavLink>
        )}
        {authUser.user_type.match(/admin|corporate/) && (
          <>
            <NavLink onClick={() => dispatch(toggleSidebar())} to="/reports/jobs_report" className={sideBarLinkClassNames}>
              Jobs Report
            </NavLink>
            <NavLink onClick={() => dispatch(toggleSidebar())} to="/reports/tech_inventory_report" className={sideBarLinkClassNames}>
              Tech Inventory Report
            </NavLink>
          </>
        )}
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/inventory" className={sideBarLinkClassNames}>
          Inventory
        </NavLink>
        {authUser.user_type.match(/admin|corporate/) ? (
          <NavLink onClick={() => dispatch(toggleSidebar())} to="/billing" className={sideBarLinkClassNames}>
            Billing
          </NavLink>
        ) : (
          <>
          <NavLink onClick={() => dispatch(toggleSidebar())} to={`/billing/time_keeper?userId=${authUser.id}&entryDate=${formattedDate}`} className={sideBarLinkClassNames}>
            Time Keeper
          </NavLink>
          <NavLink onClick={() => dispatch(toggleSidebar())} to={`/billing/view_jobs`} className={sideBarLinkClassNames}>
            View Jobs
          </NavLink>

          {authUser.user_type.match('supervisor') && (
            <NavLink onClick={() => dispatch(toggleSidebar())} to={`/billing/hours_approval`} className={sideBarLinkClassNames}>
              Hours Approval
            </NavLink>
          )}

          </>
        )}
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/billing/job_routes" className={sideBarLinkClassNames}>
          Job Routes
        </NavLink>
      </nav>
    </div>
  );
}

export default Sidebar;