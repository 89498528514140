import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  appCollection: [],
  appResource: null,
  success: false,
  loading: false,
  error: null,
  pageCount: 1,
  totalItems: 0,
  currentPage: 1,
  nextPage: null,
  prevPage: null,
  pageSize: 25,
};

const errorHandler = (error) => {
  return error.response?.data?.message || error.message || 'Something went wrong';
}

export const fetchAppCodes = createAsyncThunk(
  'appCodes/all',
  async ({areaId, navigate}, { rejectWithValue }) => {
    try {
      const msoId = localStorage.getItem('msoId');

      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/areas/${areaId}/app_codes?per=9999`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      console.log('error', error)
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMsoAppCodes = createAsyncThunk(
  'appCodes/msoAll',
  async ({
    page = 1,
    per = 25,
    search = '',
    sorting = [],
    areaId = null,
    navigate }, { rejectWithValue }) => {

  const msoId = localStorage.getItem('msoId');
  let queryParams = new URLSearchParams({
    page: page,
    per: per,
  });

  if (search) queryParams.append('search', search);
  if (areaId && areaId != 0) queryParams.append('area_id', areaId);

  let sortBy = '';
  let direction = '';
  const sortField = sorting.find(s => s.selected);

  sortBy = sortField ? sortField.id : 'id';
  direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

  if (sortBy) {
    queryParams.append('sort_by', sortBy);
    queryParams.append('direction', direction);
  }

try {
  const response = await fetchWithAuth(
    `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/app_codes?${queryParams.toString()}`
  , navigate);

  return response;
} catch (error) {
  return rejectWithValue(error.message);
}
});

export const fetchMsoAppCode = createAsyncThunk(
  'appCodes/MsofetchById',
  async ({ codeId, navigate }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/app_codes/${codeId}`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchAppCode = createAsyncThunk(
  'appCodes/fetchById',
  async ({ codeId, areaId, navigate }, { rejectWithValue }) => {
    try {
      const msoId = localStorage.getItem('msoId');
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/areas/${areaId}/app_codes/${codeId}`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const addAppCode = createAsyncThunk(
  'appCodes/addAppCode',
  async ({formData, navigate}, { rejectWithValue }) => {
    try {
      const msoId = localStorage.getItem('msoId');
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/app_codes`,
        {
          method: 'POST',
          body: JSON.stringify({ app_code: formData }),
        },
        navigate
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);

export const editAppCode = createAsyncThunk(
  'appCodes/editAppCode',
  async ({codeId, formData, navigate}, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/app_codes/${codeId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ app_code: formData }),
        },
        navigate
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);


const appCodesSlice = createSlice({
  name: 'appCodes',
  initialState,
  reducers: {
    clearAppCodes: (state) => {
      state.data = null;
      state.error = null;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppCodes.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    })
    .addCase(fetchAppCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    })
    .addCase(fetchAppCodes.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })
    .addCase(fetchAppCode.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchAppCode.fulfilled, (state, action) => {
      state.loading = false;
      state.appResource = action.payload;
      state.error = null;
    })
    .addCase(fetchAppCode.rejected, (state, action) => {
      state.loading = false;
      state.appResource = null;
      state.error = action.error.message;
    })
    .addCase(fetchMsoAppCodes.pending, (state) => {
      state.loading = true;
      state.appCollection = [];
    })
    .addCase(fetchMsoAppCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.appCollection = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    })
    .addCase(fetchMsoAppCodes.rejected, (state, action) => {
      state.loading = false;
      state.appCollection = [];
      state.error = action.error.message;
    })

    .addCase(fetchMsoAppCode.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchMsoAppCode.fulfilled, (state, action) => {
      state.loading = false;
      state.appResource = action.payload;
      state.error = null;
    })
    .addCase(fetchMsoAppCode.rejected, (state, action) => {
      state.loading = false;
      state.appResource = null;
      state.error = action.error.message;
    })
    .addCase(editAppCode.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editAppCode.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.appResource = action.payload;
    })
    .addCase(editAppCode.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(addAppCode.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    })
    .addCase(addAppCode.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.appCollection.push(action.payload)
    })
    .addCase(addAppCode.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    });
  },
});

export const {
  selectedCompanyId,
  setPageSize,
  setSuccess,
  setError,
  clearAppCodes
} = appCodesSlice.actions;

export default appCodesSlice.reducer;


