import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';
import moment from 'moment';

const initialState = {
  data: [],
  history: [],
  serialAreaId: 0,
  meta: {},
  loading: false,
  addInventoryLoading: false,
  unknownSerials: null,
  pageCount: 1,
  totalItems: 0,
  currentPage: 1,
  nextPage: null,
  prevPage: null,
  pageSize: 25,
  searchQuery: '',
  errorMessage: '',
};

export const fetchInventories = createAsyncThunk(
  'inventories/all',
  async ({
    page = 1,
    per = 25,
    search = '',
    sorting = [],
    actionStatus,
    selectedAreaId,
    userId,
    selectedType,
    navigate
  }) => {
    const msoId = localStorage.getItem('msoId');

    let queryParams = new URLSearchParams({
      page: page,
      per: per,
    });

    if (search) queryParams.append('search', search);

    let sortBy = '';
    let direction = '';
    const sortField = sorting.find(s => s.selected);

    sortBy = sortField ? sortField.id : 'id';
    direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

    if (sortBy) {
      queryParams.append('sort_by', sortBy);
      queryParams.append('direction', direction);
    }

    if (actionStatus && actionStatus.toString() !== '0') {
      queryParams.append('action_status_id', actionStatus);
    }

    if (selectedAreaId && selectedAreaId.toString() !== '0') {
      queryParams.append('area_id', selectedAreaId);
    }

    if (userId && userId.toString() !== '0') {
      queryParams.append('user_id', userId);
    }

    if (selectedType && selectedType.toString() !== '0') {
      queryParams.append('inventory_type_id', selectedType);
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories?${queryParams.toString()}`;

    return fetchWithAuth(url, {}, navigate);
  }
);

export const exportInventories = createAsyncThunk(
  'inventories/exportInventories',
  async ({ search = '', sorting = [], navigate }) => {
    const msoId = localStorage.getItem('msoId');

    let sortBy = '';
    let direction = '';

    const sortField = sorting.find(s => s.selected);
    sortBy = sortField ? sortField.id : 'id';
    direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/export_to_email?search=${search}&sort_by=${sortBy}&direction=${direction}`;

    return fetchWithAuth(url, {}, navigate);
  }
);

export const fetchHistory = createAsyncThunk(
  'inventories/history',
  async ({ inventoryId, navigate }) => {
    const msoId = localStorage.getItem('msoId');
    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/${inventoryId}/inventory_histories`;
    return fetchWithAuth(url, {}, navigate);
  }
);

export const addInventory = createAsyncThunk(
  'inventories/addInventory',
  async ({ formData, meta }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/add_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData,
          meta: meta
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const transferInventory = createAsyncThunk(
  'inventories/transferInventory',
  async ({ formData, meta }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/transfer_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData,
          meta
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const issueInventory = createAsyncThunk(
  'inventories/issueInventory',
  async ({ formData, meta }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/issue_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData,
          meta: meta
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const areaBySerial = createAsyncThunk(
  'inventories/areaBySerial',
  async ({ formData, meta }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
console.log(formData)
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/area_by_serial`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData,
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const returnInventory = createAsyncThunk(
  'inventories/returnInventory',
  async ({ formData }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/return_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editMissingAccountNumber = createAsyncThunk(
  'inventories/editMissingAccountNumber',
  async ({ formData, inventoryId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/${inventoryId}`;
      const response = await fetchWithAuth(url, {
        method: 'PUT',
        body: JSON.stringify({
          inventory: formData
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const techInventoryReport = createAsyncThunk(
  'inventories/techInventoryReport',
  async ({ startDate, endDate, areaId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/tech_inventory_report`;
    const response = await fetchWithAuth(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        tech_report: {
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          area_id: areaId
        }
      })
    }, 'blob');

    const blob = response
    const urlBlob = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlBlob;
    link.download = 'TechInventory.xlsx';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(urlBlob);
  }
);


const inventorySlice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {
    clearInventories: () => initialState,
    clearUnknownSerials: (state) => {
      state.unknownSerials = null;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventories.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
      state.errorMessage = '';
    })
    .addCase(fetchInventories.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.errorMessage = '';
      if (action.payload.meta) {
        const pagination = action.payload.meta.pagination;
        state.pageCount = pagination.total_pages;
        state.totalItems = pagination.total_items;
        state.currentPage = pagination.current_page;
        state.prevPage = pagination.prev_page;
        state.nextPage = pagination.next_page;
      }

      state.error = null;
    })
    .addCase(fetchInventories.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })

    .addCase(exportInventories.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
      state.errorMessage = '';
    })
    .addCase(exportInventories.fulfilled, (state, action) => {
      state.loading = false;
      state.errorMessage = '';
      state.error = null;
    })
    .addCase(exportInventories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })

    .addCase(fetchHistory.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
    })
    .addCase(fetchHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.history = action.payload.data;
      state.error = null;
    })
    .addCase(fetchHistory.rejected, (state, action) => {
      state.loading = false;
      state.history = [];
      state.success = false;
      state.error = action.error.message;
    })
    .addCase(addInventory.pending, (state) => {
      state.loading = false;
      state.success = false;
      state.addInventoryLoading = true;
    })
    .addCase(addInventory.fulfilled, (state, action) => {
      state.addInventoryLoading = false;
      state.loading = true;
      state.success = true;
    })
    .addCase(addInventory.rejected, (state, action) => {
      state.addInventoryLoading = false;
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    })
    .addCase(returnInventory.pending, (state) => {
      state.loading = true;
    })
    .addCase(returnInventory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(returnInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(issueInventory.pending, (state) => {
      state.loading = true;
    })
    .addCase(issueInventory.fulfilled, (state, action) => {
      state.loading = false;
      state.unknownSerials = action.payload;
    })
    .addCase(issueInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(transferInventory.pending, (state) => {
      state.loading = true;
    })
    .addCase(transferInventory.fulfilled, (state, action) => {
      state.loading = false;

    })
    .addCase(transferInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(techInventoryReport.pending, (state) => {
      state.loading = true;
    })
    .addCase(techInventoryReport.fulfilled, (state, action) => {
      state.loading = false;

    })
    .addCase(techInventoryReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(areaBySerial.pending, (state) => {
      state.serialAreaId = 0
      state.loading = true;
    })
    .addCase(areaBySerial.fulfilled, (state, action) => {
      state.loading = false;
      state.serialAreaId = action.payload.area_id
    })
    .addCase(areaBySerial.rejected, (state, action) => {
      state.serialAreaId = 0
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(editMissingAccountNumber.pending, (state) => {
      state.loading = true;
    })
    .addCase(editMissingAccountNumber.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.data.findIndex(inventory => inventory.id === action.payload.id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    })
    .addCase(editMissingAccountNumber.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {
  clearInventories,
  clearUnknownSerials,
  setErrorMessage,
  setPageSize,
  setSearchQuery,
} = inventorySlice.actions;

export default inventorySlice.reducer;
