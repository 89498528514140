import React, { useState, useCallback, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import HeaderTitleBar from "components/HeaderTitleBar";
import AreaSelectBox from "components/dropdowns/AreaSelectBox";
import TechniciansSelectBox from "components/dropdowns/TechniciansSelectBox";
import FormDatePicker from "components/formHelpers/FormDatePicker";
import FormTextInput from "components/formHelpers/FormTextInput";
import FormSelectBox from "components/formHelpers/FormSelectBox";
import TosForm from "components/formHelpers/TosForm";
import moment from "moment";
import { fetchTechList } from "features/technician/techniciansSlice";
import { useDispatch, useSelector } from "react-redux";
import { titleize } from "i/lib/methods";
import {
  fetchHourlyApproval,
  addHourly,
  editHourly,
  approveOrDeny
 } from 'features/billing/hourlyApprovalSlice';

const TimeKeeper = () => {
  const [qParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authUser = useSelector((state) => state.authUser);
  const companyId = qParams.get('companyId');
  const queryAreaId = qParams.get('areaId');
  const queryEntryDate = qParams.get('entryDate');
  let queryUserId = qParams.get('userId');

  if (authUser.user_type === 'tech') {
    queryUserId = authUser.id
  }

  const [keeperId, setKeeperId] = useState(0);
  const [userId, setUserId] = useState(queryUserId || 0);
  const [areaId, setAreaId] = useState(queryAreaId || 0)
  const [entryDate, setEntryDate] = useState(() => {
      const date = new Date(queryEntryDate)
      date.setHours(date.getHours() + 6);
      return date;
    }
  );

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [totalHours, setTotalHours] = useState(0);
  const [deductionTime, setDeductionTime] = useState('');
  const [timeWithDeductionString, setTimeWithDeductionString] = useState('');

  const {
    techCollection,
    loading: techsLoading
  } = useSelector((state) => state.technicians);

  const generateTimeSlots = () => {
    const startTime = moment().hour(6).minute(0);
    const endTime = moment().hour(23).minute(0);
    const timeSlots = [];

    while (startTime.isBefore(endTime) || startTime.isSame(endTime)) {
      const label = startTime.format('hh:mm A');
      timeSlots.push({ label, value: label });
      startTime.add(15, 'minutes');
    }
    return timeSlots;
  };

  const generateDeductionSlots = () => {
    const increments = [];

    for (let minutes = 0; minutes <= 1425; minutes += 15) {
      const hoursDecimal = (minutes / 60).toFixed(2);
      const label = `${minutes} min (${hoursDecimal} hours)`;
      increments.push({ value: minutes, label });
    }

    return increments;
  }

  const timeSlots = generateTimeSlots();
  const minuteSlots = generateDeductionSlots();
  const { hourlyApprovalCollection } = useSelector((state) => state.hourlyApproval);

  const fetchHourlyApprovalData = useCallback(() => {
    if (areaId === null) return;

    dispatch(fetchHourlyApproval({
      companyId: companyId,
      areaId: areaId,
      userId: userId,
      entryDate: entryDate
    }));
  }, [ dispatch, areaId, entryDate, userId ])

  useEffect(() => {
    fetchHourlyApprovalData();
  }, [fetchHourlyApprovalData]);

  const fetchTechData = useCallback(() => {
    if (!areaId) return;

    dispatch(fetchTechList({ companyId: companyId, navigate}));
  }, [dispatch, companyId, navigate]);

  useEffect(() => {
    fetchTechData();
  }, [fetchTechData]);


  const handleChange = (event) => {
    const { name, value } = event.target;

    switch(name) {
      case 'area_id':
        setAreaId(value);
        break;
      case 'user_id':
        setUserId(value);
        setKeeperId(0);
        setStartTime('');
        setEndTime('');
        setTotalHours(0);
        setDeductionTime('');
        setTimeWithDeductionString('');
        break;
      case 'start_time':
        setStartTime(value);
        break;
      case 'end_time':
        setEndTime(value);
        break;
      case 'deduction_time':
        setDeductionTime(value);
        break;
      default:
        break;
    }
  }

  const getWeekFromDate = (date) => {
    const givenDate = moment(date);
    const monday = givenDate.clone().startOf('isoWeek');
    const sunday = givenDate.clone().endOf('isoWeek');

    const weekDays = [];
    for (let day = monday; day.isSameOrBefore(sunday); day.add(1, 'days')) {
      weekDays.push({ weekday: day.format('dddd').toLowerCase(), date: day.format('YYYY-MM-DD') });
    }

    return weekDays;
  };

  const getTimeParts = (addedTime) => {
    const timeParts = addedTime.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    return { hours, minutes }

  }

  const addTimeToDate = (date, addedTime) => {
    if (!addedTime) return '00:00:00'

    const timeParts = getTimeParts(addedTime);
    const formattedTime = moment(date)
      .add(timeParts.hours, 'hours')
      .add(timeParts.minutes, 'minutes')

    return formatHours(formattedTime);
  }

  const formatHours = (date) => {
    if (date) return moment(date).format("hh:mm A");

    return '00:00:00';
  }

  const addStructuredTimeToDate = (time) => {
    const parsedDate = moment(entryDate);
    const [timePart, period] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);

    const formattedHours = period === 'PM' && hours !== 12 ? hours + 12 : hours === 12 && period === 'AM' ? 0 : hours;

    parsedDate.set({ hour: formattedHours, minute: minutes });

    return parsedDate;
  };

  const getTotalMinutesSinceMidnight = (date, addedTime)  => {
    if (!addedTime) return '00:00:00'
    const timeParts = getTimeParts(addedTime);
    const formattedTime = moment(date)
      .add(timeParts.hours, 'hours')
      .add(timeParts.minutes, 'minutes');

    const hours = formattedTime.hour();    ;
    const minutes = formattedTime.minutes();;

    let totalMinutes = hours * 60 + minutes;
    totalMinutes = Math.round(totalMinutes / 15) * 15;

    return totalMinutes;
  };

  const convertMinutes = (minutes) => {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return h + ':' + m;
  }

  const calculateTotalHours = () => {
    if (startTime === '00:00:00' || endTime === '00:00:00') {
      setTimeWithDeductionString(`No hours set for ${moment(entryDate).format('dddd')}`);
      return;
    }
    const start = addStructuredTimeToDate(startTime);
    const end =  addStructuredTimeToDate(endTime);
    const totalDuration = moment.duration(end.diff(start));

    const deductionMinutes = (deductionTime === '00:00:00') ? 0 : deductionTime;
    const finalDurationMinutes = totalDuration.asMinutes() - deductionMinutes;
    const correctedTime = convertMinutes(finalDurationMinutes);
    const timeParts = correctedTime.split(':')
    const hours = timeParts[0];
    const minutes = timeParts[1];

    setTotalHours(correctedTime)
    setTimeWithDeductionString(`${start.format('dddd')} hours after deduction: ${hours}:${minutes}`);
  };

  useEffect(() => {

    if (typeof(hourlyApprovalCollection?.data) === 'undefined') return;

    const techId = Object.keys(hourlyApprovalCollection.data)[0];
    const tech = hourlyApprovalCollection.data[techId];
    const week = getWeekFromDate(moment(entryDate).format('YYYY-MM-DD'));

    let newStartTime = '0';
    let newEndTime = '0';
    let newDeductionTime = '0';

    // setTechId(techId);
    week.forEach(day => {
      if (typeof(tech) === 'undefined') return;
      let dayData = tech[day.weekday];
      const dayDate = moment(day.date).format('YYYY-MM-DD');

      if (dayDate === moment(entryDate).format('YYYY-MM-DD')) {
        setKeeperId(dayData.id);

        newStartTime = addTimeToDate(dayDate, dayData.start_time);
        newEndTime = addTimeToDate(dayDate, dayData.end_time);
        newDeductionTime = getTotalMinutesSinceMidnight(dayDate, dayData.deduction_time);
      }
    });

    setStartTime(newStartTime);
    setEndTime(newEndTime);
    setDeductionTime(newDeductionTime);

  }, [entryDate, hourlyApprovalCollection?.data]);

  useEffect(() => {
    calculateTotalHours();
  }, [ startTime, endTime, deductionTime ])

  const handleApprove = (timeKeeperId) => {
    dispatch(approveOrDeny(timeKeeperId))
  }

  const handleDeny = (timeKeeperId) => {
    dispatch(approveOrDeny(timeKeeperId))
  }

  const renderApprovalButtons = (id, approved) => {
    if (id === 0) return;

    if (!approved) {
      return (
        <button
        onClick={() => handleApprove(id)}
        title="Approve"
        className="text-white hover:underline mr-2"
      >
      <svg
        className="h-4 w-4 text-white"
        width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z"/>
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
      </button>
      )
    } else {
      return (
        <button
          onClick={() => handleDeny(id)}
          title="Deny"
          className="text-white hover:underline mr-2"
        >
          <svg className="h-4 w-4 text-white"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z"/>
            <path d="M8 13.5v-8a1.5 1.5 0 0 1 3 0v6.5m0 -6.5v-2a1.5 1.5 0 0 1 3 0v8.5m0 -6.5a1.5 1.5 0 0 1 3 0v6.5m0 -4.5a1.5 1.5 0 0 1 3 0v8.5a6 6 0 0 1 -6 6h-2a7 6 0 0 1 -5 -3l-2.7 -5.25a1.4 1.4 0 0 1 2.75 -2l.9 1.75" />
          </svg>
        </button>
      )
    }
  }

  const renderWeek = () => {
    if (!hourlyApprovalCollection || typeof(hourlyApprovalCollection.data) === 'undefined') return;

    const techId = Object.keys(hourlyApprovalCollection.data)[0];
    const tech = hourlyApprovalCollection.data[techId];
    const week = getWeekFromDate(moment(entryDate).format('YYYY-MM-DD'));

    const cells = week.map((day, index) => {
      if (typeof(tech) === 'undefined') {
        return (
          <tr key={index} className={`text-sm text-gray h-8`}>
            <td className="pl-2 pr-2">{titleize(day.weekday)}</td>
            <td className="pl-2 pr-2">{moment(day.date).format('MM/DD/YYYY')}</td>
            <td className="pl-2 pr-2">00:00:00</td>
            <td className="pl-2 pr-2">00:00:00</td>
            <td className="pl-2 pr-2">00:00:00</td>
            <td className="pl-2 pr-2">0</td>
            <td className="pl-2 pr-2"></td>
          </tr>
        );
      }

      let dayData = tech[day.weekday];
      let bgCls = (parseFloat(dayData.total_hours || 0.0) > 0) ? 'bg-green-400 text-black border-b-2' : 'bg-gray-200';

      const dayDate = moment(day.date).format('YYYY-MM-DD');
      const startTimeDisplay = addTimeToDate(dayDate, dayData.start_time);
      const endTimeDisplay = addTimeToDate(dayDate, dayData.end_time);

      if (dayDate === moment(entryDate).format('YYYY-MM-DD')) {
        bgCls = 'bg-blue-500 text-blue-100';
      }

      return (
        <tr key={index} className={`text-sm text-gray h-8 ${bgCls}`}>
          <td className="pl-2 pr-2">{titleize(day.weekday)}</td>
          <td className="pl-2 pr-2">{moment(day.date).format('MM/DD/YYYY')}</td>
          <td className="pl-2 pr-2">{startTimeDisplay}</td>
          <td className="pl-2 pr-2">{endTimeDisplay}</td>
          <td className="pl-2 pr-2">{dayData.deduction_time || '00:00:00'}</td>
          <td className="pl-2 pr-2">{dayData.total_hours || '0.0'}</td>
          <td className="pl-2 pr-2">
            {authUser.user_type === 'tech' ? (
               dayData.approved.toString()
            ) : (
              renderApprovalButtons(dayData.id, dayData.approved)
            )}
          </td>
        </tr>
      );
    });

    return cells;
  };

  const handleStartChange = (date) => {
    setEntryDate(date);
  }

  const handleBack = () => {
    navigate('/billing/hours_approval')
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedDeductionTime = (deductionTime !== '00:00:00') ? new Date(deductionTime * 60000).toISOString().substr(11, 8) : deductionTime;

    if (keeperId === 0) {
      dispatch(addHourly({
        formData: {
          user_id: userId,
          company_id: companyId,
          entry_date: entryDate,
          start_time: moment(startTime, "hh:mm A").format("HH:mm"),
          end_time:  moment(endTime, "hh:mm A").format("HH:mm"),
          timekeeper_type: 0,
          deduction_time: updatedDeductionTime,
          total_hours: totalHours,
          area_id: areaId
        }
      }))
    } else {
      dispatch(editHourly({
        timeKeeperId: keeperId,
        formData: {
          user_id: userId,
          company_id: companyId,
          entry_date: entryDate,
          start_time: moment(startTime, "hh:mm A").format("HH:mm"),
          end_time:  moment(endTime, "hh:mm A").format("HH:mm"),
          timekeeper_type: 0,
          deduction_time: updatedDeductionTime,
          total_hours: totalHours,
          area_id: areaId
        }
      }))
    }

    return false;
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={`Time Keeper`}
          showBtn={true}
          btnTitle='Back'
          btnClick={handleBack}
        />
      </div>

      <div className="p-4 bg-white shadow rounded-lg">
        <div className="">
          <TosForm onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">
            <AreaSelectBox
              label={'Area'}
              name={'area_id'}
              zeroOption={'Select an Area'}
              value={areaId || "0"}
              onChange={handleChange}
              useTarget={false}
              horizontal={true}
            />

            {authUser.user_type !== 'tech' && (
              <TechniciansSelectBox
                name={'user_id'}
                label={'Technician'}
                value={userId || "0"}
                zeroOption={'Select a Technician'}
                onChange={handleChange}
                data={techCollection}
                useTarget={false}
                horizontal={true}
                companyId={companyId}
                areaId={areaId}
              />
            )}

            <FormDatePicker
              label={'Entry Date'}
              name="entry_date"
              onChange={handleStartChange}
              placeholder="Select a Date"
              value={entryDate}
              horizontal={true}
            />

            <FormSelectBox
              label={`Start Time`}
              name="start_time"
              onChange={handleChange}
              keyField={'label'}
              valueField={'value'}
              zeroOption={'Select a Start Time'}
              defaultValue={startTime}
              horizontal={true}
              data={timeSlots}
            />

            <FormSelectBox
              label={`End Time`}
              name="end_time"
              onChange={handleChange}
              keyField={'label'}
              valueField={'value'}
              zeroOption={'Select an End Time'}
              defaultValue={endTime || "0"}
              horizontal={true}
              data={timeSlots}
            />

            <FormSelectBox
              label={`Deductions`}
              name="deduction_time"
              onChange={handleChange}
              keyField={'label'}
              valueField={'value'}
              zeroOption={'Select a Deduction Time'}
              defaultValue={deductionTime || "0"}
              horizontal={true}
              data={minuteSlots}
            />
            <div className="mt-4 mb-4 text-gray-800 text-sm">
              {timeWithDeductionString}
            </div>

            <div>
              <button
                type="submit"
                className="w-100 bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Set Hours
              </button>
            </div>
          </TosForm>
        </div>

        <table className="w-full divide-y divide-gray-200 mt-10">
            <thead className="bg-gray-50">
              <tr>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Day</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Date</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Clock in</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Clock out</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Deductions</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Total Hours</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Approved</th>
                </tr>
            </thead>
            <tbody>
              {hourlyApprovalCollection && renderWeek()}
            </tbody>
          </table>
      </div>


    </>
  )
}

export default TimeKeeper;