import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectSubdomain } from '../features/subdomain/subdomainSlice';
import { selectAuthToken } from '../features/auth/authSlice';
import { selectAuthUser } from '../features/auth/authUserSlice';
import { toggleSidebar } from '../features/siteFeatures/siteFeaturesSlice';
import { clearAuthUser } from '../features/auth/authUserSlice';

import LogoutModal from './LogoutModal';
import { clearAuthToken } from 'features/auth/authSlice';

function Header() {
  const subdomain = useSelector(selectSubdomain);
  const user = useSelector((state) => state.authUser);
  const token = useSelector(selectAuthToken);

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const navigate = useNavigate();

  if (!token || !user) {
    navigate('login')
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen && !event.target.closest('.dropdown')) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/logout`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          user: JSON.parse(localStorage.getItem('user'))
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setLogoutModalOpen(false);

      navigate('/?logout=true')
    } catch (error) {
      console.error('Failed to logout', error);
    }
  };

  const headerTitle = () => {
    const msoName = localStorage.getItem('subdomain');
    if (msoName) {
      return `${msoName.toUpperCase()} Dashboard`;
    }

    return `${msoName} Dashboard`
  }

  return (
    <header className="flex items-center justify-between p-4 bg-white border-b border-gray-200">
      <button
        className="text-gray-500 focus:outline-none lg:hidden"
        onClick={() => dispatch(toggleSidebar())}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
      <div className="text-2xl font-semi text-gray-900">
        {headerTitle()}
      </div>
      <div className="relative dropdown">
        <div className="flex items-center space-x-2 cursor-pointer" onClick={toggleDropdown}>
          <span>Welcome, {user.full_name}</span>

          <svg
            className="h-6 w-6 text-blue-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
          </svg>
        </div>
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-md">
            <a href="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Profile</a>
            <button
                  onClick={() => setLogoutModalOpen(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Logout
                </button>
          </div>
        )}
      </div>
      <LogoutModal
        isOpen={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        onConfirm={handleLogout}
      />
    </header>
  );
}

export default Header;


