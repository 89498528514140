import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { denyAllJobRateCode } from '../../../../features/jobRoutes/jobRateCodesSlice';

const JobDetailsAppCodeDenyAllModal = ({ isOpen, onClose, jobRouteId, companyId, onSave }) => {
  const dispatch = useDispatch();

  const [denyNote, setDenyNote] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(denyAllJobRateCode({ jobRouteId, companyId, denyNote, onSave }));

    onClose();
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">Deny Code</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <textarea
                className="w-full p-2 border border-gray-300 rounded"
                rows="4"
                value={denyNote}
                onChange={(e) => setDenyNote(e.target.value)}
                placeholder="Enter denial reason here..."
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Deny
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default JobDetailsAppCodeDenyAllModal;
