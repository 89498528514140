import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import CodeDataTable from './CodeDataTable';
import 'tailwindcss/tailwind.css';
import HeaderTitleBar from '../HeaderTitleBar';

import {
  setPageSize,
  fetchMsoAppCodes
} from 'features/appCodes/appCodesSlice';

const Codes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ViewCode = ({ field, row }) => {
    return <Link
      to={`/app_codes/${row.original.id}`}
      onClick={() => { navigate( `/app_codes/${row.original.id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original[field]}
      </Link>;
  }

  const columns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: ({ getValue, row }) => <ViewCode field={'name'} value={getValue()} row={row} />
    },
    { header: 'Code Type', accessorKey: 'code_type' },
    { header: 'Code', accessorKey: 'code' },
    { header: 'Value', accessorKey: 'value' },
    { header: 'Qty Locked', accessorKey: 'quantity_locked' },
    { header: 'Area', accessorKey: 'area_name' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const {
    appCollection,
    loading,
    pageCount,
    totalItems,
    pageSize
  } = useSelector((state) => state.appCodes);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);
  const [selectedAreaId, setSelectedAreaId] = useState(null);

  const fetchAppCodesData = useCallback(() => {
    dispatch(fetchMsoAppCodes({
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      areaId: selectedAreaId,
      sorting,
      navigate
    }));
  }, [ dispatch, pageIndex, pageSize, searchQuery, selectedAreaId, sorting, navigate ]);

    useEffect(() => {
      fetchAppCodesData();
    }, [dispatch, fetchAppCodesData]);


    const handlePageChange = (newPageIndex, newPageSize) => {
      setPageIndex(newPageIndex);
      if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
    };

    const handleSortingChange = (newSorting) => {
      setSorting(newSorting);
      setSortOverride(!sortOverride);
    };

    const handleSearchChange = (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
      setPageIndex(0);
    };

    const handleCreateClick = () => {
      navigate('/app_codes/create')
    }

    const handleAreaChange = (e) => {
      const { value } = e.target;
      setSelectedAreaId(value);
    }

    return (
      <>
        <div className="p-4 mb-6 bg-white shadow rounded-lg">
          <HeaderTitleBar
            title='App Codes'
            showBtn={true}
            btnTitle='Create Code'
            btnClick={handleCreateClick}
          />
        </div>

        <CodeDataTable
          columns={columns}
          data={appCollection}
          pageCount={pageCount}
          totalItems={totalItems}
          pageIndex={pageIndex}
          pageSize={pageSize}
          sorting={sorting}
          loading={loading}
          handleAreaChange={handleAreaChange}
          selectedAreaId={selectedAreaId}
          onPageChange={handlePageChange}
          onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
          onSortingChange={handleSortingChange}
          onSearchChange={handleSearchChange}
        />
      </>
    );
  };


export default Codes;