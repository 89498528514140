import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobDetailsNoteModal from './modals/JobDetailsNoteModal';
import { setLoading, fetchJobRouteNotes, deleteJobRouteNote } from '../../../features/jobRoutes/jobRouteNotesSlice';

import 'tailwindcss/tailwind.css';
import moment from 'moment';

const JobDetailsNotes = ({ jobRoute }) => {
  const dispatch = useDispatch();

  const jobRouteNoteTypes = jobRoute.job_route_note_types;
  const jobRouteId = jobRoute.id;
  const companyId = jobRoute.company_id;
  const [showModal, setShowModal] = useState(false);
  const [selectedNoteType, setSelectedNoteType] = useState('');
  const [existingNote, setExistingNote] = useState(null);
  const { notes, loading, error } = useSelector(state => state.jobRouteNotes);
  const authUser = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(fetchJobRouteNotes({ jobRouteId, companyId }));
  }, [dispatch, jobRouteId, companyId]);

  const handleAddNote = (noteType) => {
    setSelectedNoteType(noteType);
    setExistingNote('');
    setShowModal(true);
  };

  const handleEditNote = (note) => {
    setSelectedNoteType(note.note_type);
    setExistingNote(note);
    setShowModal(true);
  };

  const handleDeleteNote = async (note) => {
    dispatch(setLoading(true));
    const noteId = note.id;

    await dispatch(deleteJobRouteNote({ jobRouteId, companyId, noteId }));

    dispatch(setLoading(false));
    setSelectedNoteType('');
    setExistingNote(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNoteType('');
    setExistingNote(null);
  };

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  const renderAddNoteButtons = (noteType) => {
    const isTech = authUser.user_type === 'tech'

    if (isTech) {
      if (noteType === 'tech') {
        return (
          <button
          onClick={() => handleAddNote(noteType)}
          className="bg-transparent hover:bg-blue-500 text-xs text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          >
            Add {noteType.charAt(0).toUpperCase() + noteType.slice(1)} Note
          </button>
        )
      } else {
        return (
          <div></div>
        )
      }
    } else {
      return (
        <button
        onClick={() => handleAddNote(noteType)}
        className="bg-transparent hover:bg-blue-500 text-xs text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        >
          Add {noteType.charAt(0).toUpperCase() + noteType.slice(1)} Note
        </button>
      )
    }
  }

  const renderOptionButtons = (note, noteType) => {
    const isTech = authUser.user_type === 'tech'

    if (isTech) {
      if (noteType === 'tech') {
        return (
          <>
            <button
              onClick={() => handleEditNote(note)}
              className="text-xs text-white font-bold py-0 mr-1"
            >
              <svg className="h-4 w-4 text-blue-500"  viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                <line x1="16" y1="5" x2="19" y2="8" />
              </svg>
            </button>
            <button
              onClick={() => handleDeleteNote(note)}
              className="text-xs text-white font-bold"
            >
              <svg className="h-4 w-4 text-red-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <line x1="4" y1="7" x2="20" y2="7" />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>

            </button>
          </>
        )
      } else {
        return (
          <div></div>
        )
      }
    } else {
      return (
        <>
          <button
            onClick={() => handleEditNote(note)}
            className="text-xs text-white font-bold py-0 mr-1"
          >
            <svg className="h-4 w-4 text-blue-500"  viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
              <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
              <line x1="16" y1="5" x2="19" y2="8" />
            </svg>
          </button>
          <button
            onClick={() => handleDeleteNote(note)}
            className="text-xs text-white font-bold"
          >
            <svg className="h-4 w-4 text-red-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <line x1="4" y1="7" x2="20" y2="7" />
              <line x1="10" y1="11" x2="10" y2="17" />
              <line x1="14" y1="11" x2="14" y2="17" />
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>

          </button>
        </>
      )
    }

  }

  return (
    <div className="mt-6">
      {Object.entries(jobRouteNoteTypes).map(([noteType, _]) => (
        <div key={noteType} className="mb-6">
          <div className="flex justify-between items-center border-b pb-2">
            <h4 className="text-lg font-semibold capitalize">
              {noteType} Notes
            </h4>
            {renderAddNoteButtons(noteType)}
          </div>

          <ul className="mt-4">
            {notes
              .filter((note) => note.note_type === noteType)
              .map((note) => (
                <li key={note.id} className="mb-2">
                  <p className="text-xs">{moment(note.created_at).format('MM/DD/YYYY')} {note.user_name}</p>
                  <p className="text-xs">{note.note}</p>
                  {renderOptionButtons(note, noteType)}
                </li>
              ))}
          </ul>
        </div>
      ))}

      <JobDetailsNoteModal
        isOpen={showModal}
        onClose={handleCloseModal}
        existingNote={existingNote}
        jobRouteId={jobRoute.id}
        companyId={jobRoute.company_id}
        noteType={selectedNoteType}
        loading={loading}
      />
    </div>
  );
};

export default JobDetailsNotes;