import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TosForm from 'components/formHelpers/TosForm';
import FormTextArea from 'components/formHelpers/FormTextArea';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import TechniciansSelectBox from 'components/dropdowns/TechniciansSelectBox';
import { issueInventory,
  setErrorMessage,
  clearUnknownSerials,
  areaBySerial
} from 'features/inventories/inventorySlice';


const IssueInventoryModal = ({ isOpen,
  onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    serial_numbers: '',
    area_id: '',
    user_id: ''
  });

  const [selectedAreaId, setSelectedAreaId] = useState(0);
  const [techName, setTechName] = useState(null);

  const [selectedTech, setSelectedTech] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const { unknownSerials, serialAreaId } = useSelector(state => state.inventories)

  useEffect(() => {
    if (unknownSerials === null) return;
    const serials = unknownSerials.serials;
    dispatch(clearUnknownSerials());

    if (serials.length > 0) {
      dispatch(setErrorMessage(`The following serials do not exist: ${serials.join(', ')}`))
    }
    onClose();
  }, [dispatch, unknownSerials]);

  // useEffect(() => {
  //   //if (serialAreaId === 0) setSelectedAreaId('0');

  //   setSelectedAreaId(serialAreaId);
  // }, [dispatch, serialAreaId])

  const handleAreaChange = (e) => {
    const { value, label } = e.target;
    setSelectedAreaId(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      area_id: value,
    }));
  }

  const handleTechChange = (e) => {
    const { value, label } = e.target;
    setSelectedTech(value);
    setTechName(label);

    setFormData((prevFormData) => ({
      ...prevFormData,
      user_id: value,
    }));
  }

  const handleSerialChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    formData.serial_numbers = value
    dispatch(areaBySerial({ formData }))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const meta = { tech_name: techName }

    dispatch(issueInventory({ formData, meta, navigate }))
  }

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">
            Issue Inventory (Area to Tech)
          </h3>
          <TosForm onSubmit={handleSubmit}>
            <div className="mb-4">
              <FormTextArea
                label={'Serial Numbers'}
                name="serial_numbers"
                value={formData.serial_numbers}
                onChange={handleSerialChange}
                placeholder="Serial Numbers"
                formErrors={formErrors}
                rows={3}
              />
              <AreaSelectBox
                label={'Area'}
                name={'area_id'}
                value={selectedAreaId}
                onChange={handleAreaChange}
                useTarget={false}
                warehouseOnly={localStorage.getItem('msoId') === '4'}
              />
              <TechniciansSelectBox
                label={'Technician'}
                name={'user_id'}
                value={selectedTech}
                onChange={handleTechChange}
                useTarget={false}
                areaId={selectedAreaId}
              />
            </div>


            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </TosForm>
        </div>
      </div>
    )
  );
};

export default IssueInventoryModal;
